export class DocParser {
  // static getExampleData(arg0: string, arg1: string, arg2: string, version: number): import("@quorum/models/xs-resource").Inventory {
  //   throw new Error('Method not implemented.');
  // }
  static getExampleData(area: string, path: string, operation: string, version: number) {
    var accounting = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/accounting.json');
    var associates = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/associates.json');
    var crm = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/crm.json');
    var communicator = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/communicator.json');
    var common = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/common.json');
    var deskit = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json');
    var fixedOps = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/fixed-ops.json');
    var quorum = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/quorum.json');
    var system = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/system.json');
    var training = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/training.json');
    var vehicles = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/vehicles.json');
    var reporting = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/reporting.json');
    var vendor = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json');

    switch (area) {
      case 'accounting':
        if (operation == 'get') {
          return accounting['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return accounting['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'associates':
        if (operation == 'get') {
          return associates['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return associates['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'common':
        if (operation == 'get') {
          return common['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return common['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'crm':
        if (operation == 'get') {
          return crm['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return crm['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'communicator':
        if (operation == 'get') {
          return communicator['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return communicator['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'deskit':
        if (operation == 'get') {
          return deskit['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return deskit['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'fixed-ops':
      case 'fixed-operations':
        if (operation == 'get') {
          return fixedOps['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return fixedOps['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'quorum':
        if (operation == 'get') {
          return quorum['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return quorum['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'system':
        if (operation == 'get') {
          return system['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return system['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'training':
        if (operation == 'get') {
          return training['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return training['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'vehicles':
        if (operation == 'get') {
          return vehicles['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return vehicles['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'reporting':
        if (operation == 'get') {
          return reporting['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return reporting['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
      case 'vendor':
        if (operation == 'get') {
          return vendor['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
        } else {
          return vendor['paths'][path][operation]['requestBody']['content']['application/json']['example'];
        }
    }
  }

  //getExampleData2(component: string) {}

  static getPropsDescriptionsExamples(component: string) {
    const docFile = require(`../../apps/api-documentation/src/assets/docs/v1/reporting.json`);

    let data = docFile['components']['schemas'][component].properties;
    return data;
  }

  getPropAndDescriptions(area: string, path: string, version: number, includeEmbeds: boolean = false) {
    let docFile;
    let response: any = {};

    switch (area) {
      case 'accounting':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/accounting.json`);
        break;
      case 'associates':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/associates.json`);
        break;
      case 'common':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/common.json`);
        break;
      case 'crm':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/crm.json`);
        break;
      case 'communicator':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/communicator.json`);
        break;
      case 'deskit':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/deskit.json`);
        break;
      case 'quorum':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/quorum.json`);
        break;
      case 'system':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/system.json`);
        break;
      case 'training':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/accounting.json`);
        break;
      case 'vehicles':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/vehicles.json`);
        break;
      case 'fixed-ops':
      case 'fixed-operations':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/fixed-ops.json`);
        break;
      case 'reporting':
        docFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/reporting.json`);
        break;
      // case 'vendor':
      //   docFile = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/vendor.json');
    }

    let embedCheck = docFile['paths'][path]['get']['responses']['200']['content']['application/json']['schema']['type']
      ? true
      : false;

    let primaryRef = embedCheck
      ? docFile['paths'][path]['get']['responses']['200']['content']['application/json']['schema']['allOf'][0]['$ref']
      : docFile['paths'][path]['get']['responses']['200']['content']['application/json']['schema']['$ref'];

    let breakdown = primaryRef.split('/');

    response.primaryResource = docFile['components']['schemas'][breakdown[3]]['properties'];

    if (embedCheck && includeEmbeds) {
      response.embeds = {};
      let embeds =
        docFile['paths'][path]['get']['responses']['200']['content']['application/json']['schema']['allOf'][1][
          'properties'
        ]['embedded']['allOf'][0]['properties'];
      Object.keys(embeds).forEach((key) => {
        let ref = embeds[key]['$ref'];
        let embedArea;
        let embedBreakdown;

        if (ref.indexOf(`.json`) > 0) {
          embedArea = ref.substring(ref.indexOf(`v${version}`) + 3, ref.indexOf(`.json`));
          embedBreakdown = ref.split('/')[9];
        } else {
          embedArea = area;
          embedBreakdown = ref.split('/')[3];
        }

        let embedDocFile;
        switch (embedArea) {
          case 'accounting':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/accounting.json`);
            break;
          case 'associates':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/associates.json`);
            break;
          case 'common':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/common.json`);
            break;
          case 'crm':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/crm.json`);
            break;
          case 'communicator':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/communicator.json`);
            break;
          case 'deskit':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/deskit.json`);
            break;
          case 'quorum':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/quorum.json`);
            break;
          case 'system':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/system.json`);
            break;
          case 'training':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/accounting.json`);
            break;
          case 'vehicles':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/vehicles.json`);
            break;
          case 'fixed-ops':
          case 'fixed-operations':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/fixed-ops.json`);
            break;
          case 'reporting':
            embedDocFile = require(`../../apps/api-documentation/src/assets/docs/v${version}/reporting.json`);
            break;
          // case 'vendor':
          //   docFile = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/vendor.json');
        }

        response.embeds[key] = embedDocFile['components']['schemas'][embedBreakdown]['properties'];
      });
    }
    return response;
  }

  static getRequiredParams(area: string, route: string, version: number): any {
    var accounting = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/accounting.json');
    var associates = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/associates.json');
    var crm = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/crm.json');
    var communicator = require('../../apps/api-documentation/src/assets/docs/v' +
      version.toString() +
      '/communicator.json');
    var common = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/common.json');
    var deskit = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json');
    var fixedOps = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/fixed-ops.json');
    var quorum = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/quorum.json');
    var system = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/system.json');
    var training = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/training.json');
    var vehicles = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/vehicles.json');
    var reporting = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/reporting.json');
    // var vendor = require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json');

    switch (area) {
      case 'accounting':
        return accounting['components']['schemas'][route]['required'];
      case 'associates':
        return associates['components']['schemas'][route]['required'];
      case 'common':
        return common['components']['schemas'][route]['required'];
      case 'communicator':
        return communicator['components']['schemas'][route]['required'];
      case 'crm':
        return crm['components']['schemas'][route]['required'];
      case 'deskit':
        return deskit['components']['schemas'][route]['required'];
      case 'fixed-ops':
      case 'fixed-operations':
        return fixedOps['components']['schemas'][route]['required'];
      case 'quorum':
        return quorum['components']['schemas'][route]['required'];
      case 'reporting':
        return reporting['components']['schemas'][route]['required'];
      case 'system':
        return system['components']['schemas'][route]['required'];
      case 'training':
        return training['components']['schemas'][route]['required'];
      case 'vehicle':
        return vehicles['components']['schemas'][route]['required'];
      case 'vendor':
        return vehicles['components']['schemas'][route]['required'];
    }
  }

  static checkRequiredParams(area: string, route: string, model: any, version: number): string[] {
    let params = this.getRequiredParams(area, route, version);
    let keysMissing = [];
    if (params != undefined && params.length > 0) {
      for (let i = 0; i < params.length; i++) {
        let check = Object.getOwnPropertyDescriptor(model, params[i]);
        if (check == undefined || check.value == null) {
          keysMissing.push(params[i]);
        }
      }
    }
    return keysMissing;
  }

  static validateSchemaAndExamples(version: number) {
    let docFiles: Array<{ area: string; file: any }> = [];

    docFiles.push({
      area: 'accounting',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/accounting.json'),
    });
    docFiles.push({
      area: 'associates',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/associates.json'),
    });
    docFiles.push({
      area: 'common',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/common.json'),
    });
    docFiles.push({
      area: 'communicator',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/communicator.json'),
    });
    docFiles.push({
      area: 'crm',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/crm.json'),
    });
    docFiles.push({
      area: 'deskit',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json'),
    });
    docFiles.push({
      area: 'fixed-ops',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/fixed-ops.json'),
    });
    docFiles.push({
      area: 'system',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/system.json'),
    });
    docFiles.push({
      area: 'training',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/training.json'),
    });
    docFiles.push({
      area: 'vehicles',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/vehicles.json'),
    });
    docFiles.push({
      area: 'reporting',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/reporting.json'),
    });
    docFiles.push({
      area: 'vendor',
      file: require('../../apps/api-documentation/src/assets/docs/v' + version.toString() + '/deskit.json'),
    });

    docFiles.forEach((doc) => {
      let problems: any[] = [];

      Object.keys(doc.file['paths']).forEach((path: any) => {
        if (!path.includes('workflow')) {
          Object.keys(doc.file['paths'][path]).forEach((operation) => {
            let example;
            let schema;

            if (
              doc.file['paths'][path][operation]['responses']['200'] &&
              doc.file['paths'][path][operation]['responses']['200']['content']
            ) {
              example =
                doc.file['paths'][path][operation]['responses']['200']['content']['application/json']['example'];

              let embedCheck = doc.file['paths'][path][operation]['responses']['200']['content']['application/json'][
                'schema'
              ]['type']
                ? true
                : false;

              let primaryRef = embedCheck
                ? doc.file['paths'][path][operation]['responses']['200']['content']['application/json']['schema'][
                    'allOf'
                  ][0]['$ref']
                : doc.file['paths'][path][operation]['responses']['200']['content']['application/json']['schema'][
                    '$ref'
                  ];

              let breakdown = primaryRef.split('/');

              schema = doc.file['components']['schemas'][breakdown[3]]
                ? doc.file['components']['schemas'][breakdown[3]]['properties']
                : null;

              if (example && schema) {
                let schemaKeys = Object.keys(schema);
                let exampleKeys = Object.keys(example);

                let difference = schemaKeys
                  .filter((x: any) => !exampleKeys.includes(x))
                  .concat(exampleKeys.filter((x) => !schemaKeys.includes(x)));

                if (difference.length > 0)
                  problems.push(`${path}, ${operation.toUpperCase()}, Property mismatch: ${difference.join(', ')}`);
              } else {
                problems.push(`${path}, ${operation.toUpperCase()}, Missing ${schema ? 'example' : 'scehma'}`);
              }
            }
          });
        }
      });
      if (problems.length > 0)
        console.log(`${problems.length} Problems found in ${doc.area}:`, `${problems.join('\n')}`);
    });
  }

  // static parseYaml(filepath: string) {
  //   let yaml = require('js-yaml');
  //   var str = fs.readFileSync(filepath);
  //   return yaml.safeLoad(str, { filename: filepath });
  // }

  // static getYamlExampleData(path: string, operation: string, version: number) {
  //   let yamlJson = this.parseYaml(`../../apps/api-documentation/src/assets/docs/v${version.toString()}/wip.yaml`);

  //   if (operation == 'get') {
  //     return yamlJson['paths'][path][operation]['responses']['200']['content']['application/json']['example'];
  //   } else {
  //     return yamlJson['paths'][path][operation]['requestBody']['content']['application/json']['example'];
  //   }
  // }

  // static getYamlRequiredParams(route: string, version: number): any {
  //   let yamlJson = this.parseYaml(`../../apps/api-documentation/src/assets/docs/v${version.toString()}/wip.yaml`);
  //   return yamlJson['components']['schemas'][route]['required'];
  // }

  // static checkYamlRequiredParams(route: string, model: any, version: number): string[] {
  //   let params = this.getYamlRequiredParams(route, version);
  //   let keysMissing = [];
  //   if (params != undefined && params.length > 0) {
  //     for (let i = 0; i < params.length; i++) {
  //       let check = Object.getOwnPropertyDescriptor(model, params[i]);
  //       if (check == undefined || check.value == null) {
  //         keysMissing.push(params[i]);
  //       }
  //     }
  //   }
  //   return keysMissing;
  // }
}
