import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTypes } from '@models/server-common';
import { createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { fromConversations } from '@quorum/communicator/state/conversations';
import { environment } from '@quorum/environments';
import { CommunicatorContact as Contact, Conversation, Event, Member } from '@quorum/models/xs-resource';
import { fromRouter, RouterStateService } from '@quorum/sha-router';
import { from, of } from 'rxjs';
import { flatMap, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromEvents from './events.actions';
import { EventsState } from './events.interfaces';

@Injectable()
export class EventsEffects {
  addEventToState = createEffect(() =>
    this.d.optimisticUpdate(fromEvents.ADD_EVENT_TO_STATE, {
      run: (a: fromEvents.AddEventToState, state: any) => {
        return this.apiService
          .post<Event>(`communicator/conversations/${a.payload.event.conversationId}/events`, a.payload.event, {
            headers: a.payload.headers,
          })
          .pipe(
            flatMap((event: Event) => {
              return [
                new fromConversations.UpdateConversationLastEventInState({
                  id: a.payload.event.conversationId,
                  changes: {
                    lastEventId: event.id,
                    utcLastModified: event.sentDate,
                    embedded: {
                      ...state.conversations.entities[a.payload.event.conversationId].embedded,
                      lastEvent: a.payload.event,
                    },
                  },
                }),
                new fromEvents.PostEventToServerSuccess({ newEvent: event, oldEvent: a.payload.event }),
                new fromEvents.DisplayNotificationForNewEvent(event),
              ];
            })
          );
      },
      undoAction: (a: fromEvents.AddEventToState, error) => {
        return new fromEvents.PostEventToServerFailure({ event: a.payload.event, error });
      },
    })
  );

  addEventFromSocketToState = createEffect(() =>
    this.d.optimisticUpdate(fromEvents.ADD_EVENT_FROM_SOCKET_TO_STATE, {
      run: (a: fromEvents.AddEventFromSocketToState, state: any) => {
        return from([
          new fromConversations.UpdateConversationLastEventInState({
            id: a.payload.event.conversationId,
            changes: {
              lastEventId: a.payload.event.id,
              utcLastModified: a.payload.event.sentDate,
              embedded: {
                ...state.conversations.entities[a.payload.event.conversationId].embedded,
                lastEvent: a.payload.event,
              },
            },
          }),
          new fromEvents.DisplayNotificationForNewEvent(a.payload.event),
          new fromEvents.GeneratePresignedUrlForEvent(a.payload.event),
        ]);
      },
      undoAction: (a: fromEvents.AddEventFromSocketToState, error) => {
        console.error('Error', error);
        return from([]);
      },
    })
  );

  displayNotificationForNewEvent = createEffect(
    () =>
      this.d.optimisticUpdate(fromEvents.DISPLAY_NOTIFICATION_FOR_NEW_EVENT, {
        run: (a: fromEvents.DisplayNotificationForNewEvent, state: any) => {
          const self = this;
          const auditEvents: Array<EventTypes> = [
            EventTypes.ArchivedConversation,
            EventTypes.MemberAddedToConversation,
            EventTypes.RemoveOtherFromGroup,
            EventTypes.RemoveSelfFromGroup,
            EventTypes.XselleratorEventMemberAdded,
          ];
          if (auditEvents.includes(<EventTypes>a.payload.eventTypeId)) return;

          if ('Notification' in window && !document.hasFocus()) {
            let sendingMember: Member;
            for (const [key, value] of Object.entries(state.members.entities)) {
              const member: Member = new Member(value);
              if (member.conversationId == a.payload.conversationId && member.userId == a.payload.memberId) {
                sendingMember = member;
                break;
              }
            }

            if (state.authentication.authenticatedUser.user.id == a.payload.memberId) return;

            const conversation: any = Object.values(state.conversations.entities).find(
              (c: Conversation) => a.payload.conversationId === c.id
            );

            const sendingContact: Contact = state.contacts.contacts.find(
              (c: Contact) => c.id.toString() === sendingMember.userId
            );

            Notification.requestPermission(function (permission) {
              if (permission === 'granted') {
                let tag: string = null;
                if (conversation) {
                  tag = a.payload.conversationId.toString();
                }

                const opts = {
                  body: a.payload.content,
                  icon:
                    sendingContact && sendingContact.avatarUrl
                      ? sendingContact.avatarUrl
                      : '/assets/communicator-logo.png',
                  requireInteraction: true,
                  tag, // NOTE:  Appears to be a issue in Chrome whereby replacement of Notifications opens
                  //app in new tab, rather than using same tab.
                };
                const audio = new Audio('/assets/notification.mp3');
                const notification = new Notification(
                  `${sendingMember.firstName} ${sendingMember.lastName ? sendingMember.lastName : ''}`,
                  opts
                );
                audio.play();
                notification.onclick = () => {
                  self.routerStateService.go(['/home', { outlets: { detail: `c/${a.payload.conversationId}` } }], {
                    relativeTo: '/',
                  });
                  notification.close();
                  window.focus();
                };
              }
            });
          }
        },
        undoAction: (a: fromEvents.DisplayNotificationForNewEvent, error) => {
          console.error('Error', error);
          return from([]);
        },
      }),
    { dispatch: false }
  );

  postEventToServer = createEffect(() =>
    this.d.pessimisticUpdate(fromEvents.POST_EVENT_TO_SERVER, {
      run: (a: fromEvents.PostEventToServer, state: any) => {
        return this.routerStateService.selectRouterState().pipe(
          take(1),
          switchMap((routerState) => {
            const rootPath = routerState.state.root.firstChild.firstChild.url[0].path;
            let headers = new HttpHeaders({});
            if (a.payload.template && a.payload.template.id)
              headers = headers.append('template-id', a.payload.template.id);

            return this.apiService
              .post<Event>(
                'communicator/conversations/' + a.payload.event.conversationId + '/events',
                a.payload.event,
                {
                  headers: headers,
                }
              )
              .pipe(
                switchMap((event: Event) => {
                  const actions: Action[] = [];

                  actions.push(
                    new fromConversations.UpdateConversationLastEventInState({
                      id: a.payload.event.conversationId,
                      changes: {
                        lastEventId: event.id,
                        utcLastModified: event.sentDate,
                        embedded: {
                          ...state.conversations.entities[a.payload.event.conversationId].embedded,
                          lastEvent: a.payload.event,
                        },
                      },
                    })
                  );
                  actions.push(
                    new fromEvents.PostEventToServerSuccess({
                      newEvent: event,
                      oldEvent: a.payload.event,
                    })
                  );

                  if (rootPath === 'home') {
                    actions.push(
                      new fromRouter.Go({
                        path: ['/home', { outlets: { detail: `c/${a.payload.event.conversationId}` } }],
                        extras: { replaceUrl: true },
                      })
                    );
                  } else {
                    actions.push(
                      new fromRouter.Go({
                        path: [`c/${a.payload.event.conversationId}`],
                        extras: { relativeTo: this.route.firstChild },
                      })
                    );
                  }
                  return actions;
                })
              );
          })
        );
      },
      onError: (a: fromEvents.PostEventToServer, error) => {
        return new fromEvents.PostEventToServerFailure({ event: a.payload.event, error });
      },
    })
  );

  loadEvents = createEffect(() =>
    this.d.fetch(fromEvents.GET_EVENTS_FROM_SERVER, {
      id: (a: fromEvents.GetEventsFromServer, state: any) => {
        return a.payload.conversationId;
      },
      run: (a: fromEvents.GetEventsFromServer, state: any) => {
        if (!Array.isArray(state.events.entities) || state.events.entities.length === 0) {
          return this.apiService
            .get<Event[]>('communicator/conversations/' + a.payload.conversationId + '/events', { params: a.payload })
            .pipe(
              mergeMap((events: Event[]) => {
                const actions = [];
                actions.push(new fromEvents.GetEventsFromServerSuccess({ events: events }));
                actions.push(new fromEvents.UpdateIsLoading(false));
                if (a.payload.pageNumber) {
                  actions.push(
                    new fromConversations.UpdateEventsLastQueryPageInState({ conversationId: a.payload.conversationId })
                  );
                }
                return actions;
              })
            );
        }
      },
      onError: (a: fromEvents.GetEventsFromServer, error) => {
        return of(
          new fromEvents.UpdateIsLoading(false),
          new fromEvents.GetEventsFromServerFailure({ queryParameters: a.payload, error })
        );
      },
    })
  );

  getEventsFromServerSuccess = createEffect(() =>
    this.d.fetch(fromEvents.GET_EVENTS_FROM_SERVER_SUCCESS, {
      id: (a: fromEvents.GetEventsFromServerSuccess, state: any) => {
        return a.payload;
      },
      run: (a: fromEvents.GetEventsFromServerSuccess, state: any) => {
        const actionsArray = a.payload.events.map((event) => {
          return new fromEvents.GeneratePresignedUrlForEvent(event);
        });

        return from(actionsArray);
      },
      onError: (a: fromEvents.GetEventsFromServerSuccess, error) => {
        return of(error);
      },
    })
  );

  generatePresignedUrlForEvent = createEffect(() =>
    this.d.fetch(fromEvents.GENERATE_PRESIGNED_URL_FOR_EVENT, {
      id: (action: fromEvents.GeneratePresignedUrlForEvent, state: any) => action.payload.id,

      run: (action: fromEvents.GeneratePresignedUrlForEvent, state: any) => {
        const { content } = action.payload;

        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
        const attachmentProcessedByApp = uuidRegex.test(content.split('/')[0]);

        const bucketUrlPatterns = [
          `https://${environment.s3AttachmentsBucket}.s3.amazonaws.com/`,
          `https://s3.amazonaws.com/${environment.s3AttachmentsBucket}/`,
        ];

        const matchedPattern = bucketUrlPatterns.find((pattern) => content.startsWith(pattern));

        // Not a recognized pattern, return original payload
        if (!matchedPattern && !attachmentProcessedByApp) {
          return of(new fromEvents.GeneratePresignedUrlForEventSuccess(action.payload));
        }

        const objectKey = this.getObjectKey(content, matchedPattern, attachmentProcessedByApp);

        return this.apiService
          .post('communicator/presigned-url', {
            key: objectKey,
            bucket: environment.s3AttachmentsBucket,
          })
          .pipe(
            map((presignedUrl: any) => {
              return new fromEvents.GeneratePresignedUrlForEventSuccess({
                ...action.payload,
                content: presignedUrl.url,
              });
            })
          );
      },

      onError: (action: fromEvents.GeneratePresignedUrlForEvent, error) => {
        return of(
          new fromEvents.GeneratePresignedUrlForEventFailure({
            event: action.payload,
            error,
          })
        );
      },
    })
  );

  /**
   * Helper function to parse and return the correct S3 object key.
   */
  getObjectKey(content: string, matchedPattern?: string, processedByApp?: boolean): string {
    let objectKey = '';

    if (matchedPattern) {
      const parsedUrl = new URL(content);
      objectKey = decodeURIComponent(parsedUrl.pathname);

      if (matchedPattern === `https://${environment.s3AttachmentsBucket}.s3.amazonaws.com/`) {
        objectKey = objectKey.startsWith('/') ? objectKey.slice(1) : objectKey;
      } else if (matchedPattern === `https://s3.amazonaws.com/${environment.s3AttachmentsBucket}/`) {
        const bucketPath = `/${environment.s3AttachmentsBucket}/`;
        objectKey = objectKey.startsWith(bucketPath) ? objectKey.slice(bucketPath.length) : objectKey;
      }
    } else if (processedByApp) {
      objectKey = content;
    }

    // Replace encoded '+' with spaces
    return objectKey.replace(/\+/g, ' ');
  }

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private d: DataPersistence<EventsState>,
    private routerStateService: RouterStateService
  ) {}
}
