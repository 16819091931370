import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@quorum/environments';
//import fontkit from '@pdf-lib/fontkit';
import { PDFDocument, PDFForm } from 'pdf-lib';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private formUrl = '';
  triggerPDFGeneration$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  async generateHyuICEPdf(vehicleId: string, workOrderId: string, rooftopId: string): Promise<Blob> {
    try {
      this.formUrl = `assets/Hyundai VIR ICE English.pdf`;

      // Wait for the required data
      const data = await combineLatest([
        this.getInspection(workOrderId).pipe(filter((inspection) => inspection != null)),
        this.getVehicle(vehicleId).pipe(filter((vehicle) => vehicle != null)),
        this.getBrakeMeasurements(rooftopId).pipe(filter((brakeMeasurements) => brakeMeasurements != null)),
      ])
        .pipe(
          map(([inspection, vehicle, brakeMeasurements]) => ({
            inspection,
            vehicle,
            brakeMeasurements,
          }))
        )
        .toPromise();

      // Fetch the PDF
      const [formBytes] = await Promise.all([fetch(this.formUrl).then((res) => res.arrayBuffer())]);

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(formBytes);

      // Get the form
      const form = pdfDoc.getForm();

      // // Debugging field logs
      // const fields = form.getFields();

      // fields.forEach((field) => {
      //   const type = field.constructor.name;
      //   const name = field.getName();

      //   console.log(`Field Name: ${name} as ${type}`);
      // });

      // SIGNATURE PLACEHOLDER START
      // Load the signature image
      // const signatureBytes = await fetch('assets/signature.png').then((res) => res.arrayBuffer());
      // const signatureImage = await pdfDoc.embedPng(signatureBytes);

      // const customerSignatureField = form.getSignature('customerSignature');
      // const widgets = customerSignatureField.acroField.getWidgets();

      // widgets.forEach((w) => {
      //   const rect = w.getRectangle();
      //   const page = pdfDoc.getPages()[0];

      //   // Draw the signature image over the field
      //   page.drawImage(signatureImage, {
      //     x: rect.x,
      //     y: rect.y,
      //     width: rect.width,
      //     height: rect.height,
      //   });
      // });
      // SIGNATURE PLACEHOLDER END

      // Populate the form fields
      this.populateSettings(form, data.inspection);
      this.populateVehicleInfo(form, data.inspection, data.vehicle);
      this.populateVisualChecks(form, data.inspection);
      this.populateTires(form, data.inspection);
      this.populateTireOther(form, data.inspection);
      this.populateBrakes(form, data.inspection, data.brakeMeasurements);
      this.populateCheckAndFill(form, data.inspection, 'ICE');
      this.populateBattery(form, data.inspection);
      this.populateSafety(form, data.inspection);
      this.populateService1ICE(form, data.inspection);
      this.populateService2ICE(form, data.inspection);
      this.populateService3ICE(form, data.inspection);
      this.populateAdditionalRecommendations(form, data.inspection, 'ICE');
      // this.populateNextVisit(form, data.inspection); //
      // this.populateCompleted(form, data.appointment);

      form.flatten();

      // Serialize the PDFDocument to bytes
      const pdfBytes = await pdfDoc.save();

      // Convert to a Blob and return
      return new Blob([pdfBytes], { type: 'application/pdf' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }

  async generateHyuEVPdf(vehicleId: string, workOrderId: string, rooftopId: string): Promise<Blob> {
    try {
      this.formUrl = `assets/Hyundai VIR EV English.pdf`;

      // Wait for the required data
      const data = await combineLatest([
        this.getInspection(workOrderId).pipe(filter((inspection) => inspection != null)),
        this.getVehicle(vehicleId).pipe(filter((vehicle) => vehicle != null)),
        this.getBrakeMeasurements(rooftopId).pipe(filter((brakeMeasurements) => brakeMeasurements != null)),
      ])
        .pipe(
          map(([inspection, vehicle, brakeMeasurements]) => ({
            inspection,
            vehicle,
            brakeMeasurements,
          }))
        )
        .toPromise();

      // Fetch the PDF and font files
      const [formBytes] = await Promise.all([fetch(this.formUrl).then((res) => res.arrayBuffer())]);

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(formBytes);

      // Get the form
      const form = pdfDoc.getForm();

      // // Debugging field logs
      // const fields = form.getFields();

      // fields.forEach((field) => {
      //   const type = field.constructor.name;
      //   const name = field.getName();

      //   console.log(`Field Name: ${name} as ${type}`);
      // });

      // SIGNATURE PLACEHOLDER START
      // Load the signature image
      // const signatureBytes = await fetch('assets/signature.png').then((res) => res.arrayBuffer());
      // const signatureImage = await pdfDoc.embedPng(signatureBytes);

      // const customerSignatureField = form.getSignature('customerSignature');
      // const widgets = customerSignatureField.acroField.getWidgets();

      // widgets.forEach((w) => {
      //   const rect = w.getRectangle();
      //   const page = pdfDoc.getPages()[0];

      //   // Draw the signature image over the field
      //   page.drawImage(signatureImage, {
      //     x: rect.x,
      //     y: rect.y,
      //     width: rect.width,
      //     height: rect.height,
      //   });
      // });
      // SIGNATURE PLACEHOLDER END

      // Populate the form fields
      this.populateSettings(form, data.inspection);
      this.populateVehicleInfo(form, data.inspection, data.vehicle);
      this.populateVisualChecks(form, data.inspection);
      this.populateTires(form, data.inspection);
      this.populateTireOther(form, data.inspection);
      this.populateBrakes(form, data.inspection, data.brakeMeasurements);
      this.populateCheckAndFill(form, data.inspection, 'EV');
      this.populateBattery(form, data.inspection);
      this.populateSafety(form, data.inspection);
      this.populateService1EV(form, data.inspection);
      this.populateService2EV(form, data.inspection);
      this.populateService3EV(form, data.inspection);
      this.populateAdditionalRecommendations(form, data.inspection, 'EV');
      // this.populateNextVisit(form, data.inspection);
      // this.populateCompleted(form, data.appointment);

      form.flatten();

      // Serialize the PDFDocument to bytes
      const pdfBytes = await pdfDoc.save();

      // Convert to a Blob and return
      return new Blob([pdfBytes], { type: 'application/pdf' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  }

  getInspection(inspectionId: string) {
    return this.http.get(`${environment.apiBase}/v/2/vip/inspections/${inspectionId}`).pipe(take(1));
  }

  getVehicle(vehicleId: string) {
    return this.http
      .get(`${environment.apiBase}/v/2/vehicles/vehicles/${vehicleId}`, {
        params: {
          embed: 'associate',
        },
      })
      .pipe(take(1));
  }

  getBrakeMeasurements(rooftopId: string) {
    return this.http
      .get(`${environment.apiBase}/v/2/vip/vehicle-brake-measurements`, {
        params: { rooftopId },
      })
      .pipe(take(1));
  }

  getAppointmentDetails(appointmentId: string) {
    return this.http
      .get<any[]>(`${environment.apiBase}/v/2/fixed-operations/appointments`, {
        params: {
          id: appointmentId,
          embed: 'serviceAdvisorAssociate',
        },
      })
      .pipe(
        take(1),
        map((appointments: any[]) => appointments[0])
      );
  }

  determinePowerLaneURL(): string {
    switch (environment.environment) {
      case 'development':
        return 'http://localhost:4200';
      case 'staging':
        return 'https://staging-service-lane.xsellerator.com';
      case 'production':
        return 'https://power-lane.xsellerator.com';
      default:
        return 'https://staging-service-lane.xsellerator.com';
    }
  }

  populateSettings(form: PDFForm, inspection: any) {
    const miscellaneousCategory = inspection.categories?.find(
      (category: any) => category.categoryName === 'Miscellaneous'
    );
    if (!miscellaneousCategory) return;

    const tasks = [
      { id: 'HYU_CUSTOMER_SETTINGS_RETURNED', field: 'settings' },
      { id: 'HYU_RECALLS_CHECKED', field: 'recalls' },
      { id: 'HYU_RESET_SERVICE_REMINDERS', field: 'reminders' },
      { id: 'HYU_CUSTOMER_DECLINED_MAINTENANCE', field: 'declinedMaintenance' },
    ];

    tasks.forEach(({ id, field }) => {
      const task = miscellaneousCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      if (task) {
        this.checkGTask(form, task, field);
      }
    });
  }

  populateVehicleInfo(form: PDFForm, inspection: any, vehicle: any) {
    const vinField = form.getTextField('VIN');
    vinField.setText(vehicle.vin);
    const dealershipField = form.getTextField('dealership');
    //dealershipField.setText('Dealer Info'); // PLACEHOLDER
    const customerNameField = form.getTextField('customerName');
    customerNameField.setText(`${vehicle.embedded?.associate?.firstName} ${vehicle.embedded?.associate?.lastName}`);
    const repairOrderNumberField = form.getTextField('repairOrderNumber');
    repairOrderNumberField.setText(inspection.workOrderId?.toString());
    const odometerField = form.getTextField('odometer');
    odometerField.setText(vehicle.currentMileage?.toString());
    const vehicleModelField = form.getTextField('vehicleModel');
    vehicleModelField.setText(vehicle.model);
    const vehicleYearField = form.getTextField('vehicleYear');
    vehicleYearField.setText(vehicle.year?.toString());
    const serviceNumberField = form.getTextField('serviceNumber'); // like service 1/2/3?
    //serviceNumberField.setText('80085'); // PLACEHOLDER
    const licensePlateNumberField = form.getTextField('licensePlateNumber');
    licensePlateNumberField.setText(vehicle.licensePlateNumber);

    const visitDate = inspection.inspectionDate.split('T')[0].split('-');

    const visitMonthField = form.getTextField('visitMonth');
    visitMonthField.setText(visitDate[1]);
    const visitDayField = form.getTextField('visitDay');
    visitDayField.setText(visitDate[2]);
    const visitYearField = form.getTextField('visitYear');
    visitYearField.setText(visitDate[0]);
  }

  populateVisualChecks(form: PDFForm, inspection: any) {
    const visualCheckCategory = inspection.categories?.find(
      (category: any) => category.categoryName === 'Visually Check Following Components'
    );
    if (!visualCheckCategory) return;

    const checkItems = [
      { taskName: 'Windshield', prefix: 'visualCheckWindshield' },
      { taskName: 'Wiper Blades', prefix: 'visualCheckWipers' },
      { inspectionId: 'HYU_HORN', prefix: 'visualCheckHorn' },
      { taskName: 'Interior Lights', prefix: 'visualCheckInteriorLights' },
      { taskName: 'Exterior Lights', prefix: 'visualCheckExteriorLights' },
    ];

    checkItems.forEach(({ taskName, inspectionId, prefix }) => {
      const task = taskName
        ? visualCheckCategory.tasks.find((task: any) => task.taskName.includes(taskName))
        : visualCheckCategory.tasks.find((task: any) => task.inspectionId.includes(inspectionId));

      if (task) {
        this.checkGRTaskWithPrefix(form, task, prefix);
      }
    });

    // Body condition comments (Placeholder: Uncomment and modify if needed)
    // form.getTextField('bodyConditionComments').setText('Custom body condition comments here.');
  }

  populateTires(form: PDFForm, inspection: any) {
    const tiresCategory = inspection.tires?.find((tires: any) => tires.categoryName === 'Check Tires');
    if (!tiresCategory) return;

    const tirePositions = [
      { id: 'Tires - LF', prefix: 'tiresLF' },
      { id: 'Tires - LR', prefix: 'tiresLR' },
      { id: 'Tires - RF', prefix: 'tiresRF' },
      { id: 'Tires - RR', prefix: 'tiresRR' },
    ];

    let frontPSI = '';
    let backPSI = '';

    tirePositions.forEach(({ id, prefix }) => {
      const task = tiresCategory.tasks.find((task: any) => task.taskName.includes(id));
      if (!task) return;

      this.checkGYRTaskWithPrefix(form, task, prefix);

      form.getTextField(`${prefix}_tread`).setText(task.treadDepth?.toString() || '');
      form.getTextField(`${prefix}_wear`).setText(task.wearPattern || '');

      if (id === 'Tires - LF') frontPSI = task.psiOut?.toString() || '';
      if (id === 'Tires - LR') backPSI = task.psiOut?.toString() || '';
    });

    // PLACEHOLDER fields for front and back PSI
    form.getTextField('tires_frontPSI').setText(frontPSI);
    form.getTextField('tires_backPSI').setText(backPSI);
  }

  populateTireOther(form: PDFForm, inspection: any) {
    const tiresOtherCategory = inspection.tireOther?.find((tires: any) => tires.categoryName === 'Check Tires');

    if (!tiresOtherCategory) return;

    const tasksToCheck = [
      { id: 'Rotation', key: 'tires_rotation' },
      { id: 'Alignment', key: 'tires_alignment' },
      { id: 'Replacement', key: 'tires_replacement' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = tiresOtherCategory.tasks.find((task: any) => task.taskName.includes(id));
      if (task) this.checkRTask(form, task, key);
    });
  }

  populateBrakes(form: PDFForm, inspection: any, brakeMeasurements: any) {
    const brakeCheckCategory = inspection.categories?.find((category: any) => category.categoryName === 'Check Brakes');
    if (!brakeCheckCategory) return;

    const tasks = [
      { name: 'Brakes - LF', field: 'brakesLF' },
      { name: 'Brakes - RF', field: 'brakesRF' },
      { name: 'Brakes - LR', field: 'brakesLR' },
      { name: 'Brakes - RR', field: 'brakesRR' },
    ];

    const brakeInspectionTask = brakeCheckCategory.tasks.find((task: any) =>
      task.inspectionId.includes('HYU_BRAKE_INSPECTION')
    );
    const brakeServiceTask = brakeCheckCategory.tasks.find((task: any) =>
      task.inspectionId.includes('HYU_BRAKE_SERVICE_TOGGLE')
    );

    this.checkGTask(form, brakeInspectionTask, 'brakes_inspection');
    this.checkGTask(form, brakeServiceTask, 'brakes_service');

    const brakeMeasurementIds = ['G', 'Y', 'R'].map(
      (code) => brakeMeasurements.find((brake: any) => brake.gmBrakeCode === code)?.id
    );

    tasks.forEach(({ name, field }) => {
      const task = brakeCheckCategory.tasks.find((task: any) => task.taskName.includes(name));
      if (task) {
        this.checkBrakeTaskWithPrefix(form, task, field, brakeMeasurementIds);
      }
    });
  }

  populateCheckAndFill(form: PDFForm, inspection: any, inspectionType: 'ICE' | 'EV') {
    const checkAndFillCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check & Fill'
    );

    if (!checkAndFillCategory) return;

    const tasksToCheck = [
      { name: 'Window Washer', key: 'checkWasher' },
      { name: 'Brake', key: 'checkBrake' },
    ];

    if (inspectionType === 'ICE') {
      tasksToCheck.push(
        { name: 'Engine Oil', key: 'checkOil' },
        { name: 'Coolant', key: 'checkCoolant' },
        { name: 'Transmission (if equipped with dipstick)', key: 'checkTransmission' },
        { name: 'Clutch (if equipped)', key: 'checkClutch' }
      );
    }

    tasksToCheck.forEach(({ name, key }) => {
      const task = checkAndFillCategory.tasks.find((task: any) => task.taskName.includes(name));
      this.checkOkFilledTask(form, task, key);
    });
  }

  populateBattery(form: PDFForm, inspection: any) {
    const batteryCategory = inspection.categories.find((category: any) => category.categoryName === 'Check Battery');

    if (!batteryCategory) return;

    const tasksToCheck = [
      { id: 'Battery Voltage', key: 'batteryVoltage', checkFn: this.checkGRTaskWithPrefix },
      { id: 'Battery Cables & Connections', key: 'batteryCables', checkFn: this.checkGYRTaskWithPrefix },
    ];

    let batteryVoltageTask: any = null;

    tasksToCheck.forEach(({ id, key, checkFn }) => {
      const task = batteryCategory.tasks.find((task: any) => task.taskName.includes(id));
      if (task) {
        checkFn.call(this, form, task, key);
        if (id === 'Battery Voltage') batteryVoltageTask = task;
      }
    });

    if (batteryVoltageTask) {
      form.getTextField('batteryCCA_factory').setText(batteryVoltageTask.factorySpecs?.toString() || '');
      form.getTextField('batteryCCA_actual').setText(batteryVoltageTask.actual?.toString() || '');
    }
  }

  populateSafety(form: PDFForm, inspection: any) {
    const safetyCategory = inspection.categories.find((category: any) => category.categoryName === 'Safety');

    if (!safetyCategory) return;

    const tasksToCheck = [
      { id: 'HYU_FLOOR_LINERS_CHECK', key: 'safetyLiners' },
      { id: 'HYU_CORRECT_TIRES_CHECK', key: 'safetyTires' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = safetyCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkYNTaskWithPrefix(form, task, key);
    });
  }

  populateService1ICE(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_ENGINE_OIL_FILTER', key: 'service1Oil' },
      { id: 'HYU_DRIVESHAFTS_AND_BOOTS', key: 'service1Driveshaft' },
      { id: 'HYU_GEAR_BOX_LINKAGES_BOOTS_JOINTS', key: 'service1GearBox' },
      { id: 'HYU_VACUUM_HOSE', key: 'service1Vacuum' },
      { id: 'HYU_LATCHES_HINGES_LOCKS', key: 'service1Latches' },
      { id: 'HYU_BRAKE_SYSTEM_LINES_HOSES_PARKING', key: 'service1Brake' },
      { id: 'HYU_EXHAUST_SYSTEM_MUFFLER', key: 'service1Exhaust' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateService2ICE(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_COOLING_SYSTEM_HOSES_CONNECTIONS', key: 'service2Cooling' },
      { id: 'HYU_SUSPENSION_MOUNTING_BOLTS', key: 'service2Suspension' },
      { id: 'HYU_PARKING_CLUTCH_BRAKE_PEDAL_FREE_PLAY', key: 'service2PBrake' },
      { id: 'HYU_AIR_INTAKE_HOSE', key: 'service2AirIntake' },
      { id: 'HYU_AC_HEATING_FUNCTION', key: 'service2ACHeating' },
      { id: 'HYU_AC_COMPRESSOR_FUNCTION', key: 'service2ACCompressor' },
      { id: 'HYU_CABIN_AIR_FILTER_REPLACE', key: 'service2CabinFilter' },
      { id: 'HYU_ENGINE_AIR_FILTER_INSPECT', key: 'service2EngineFilter' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateService3ICE(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_FUEL_FILLER_CAP', key: 'service3FuelCap' },
      { id: 'HYU_FUEL_TANK', key: 'service3FuelTank' },
      { id: 'HYU_FUEL_LINES_HOSES_CONNECTIONS', key: 'service3FuelLines' },
      { id: 'HYU_VAPOR_HOSE', key: 'service3VaporHose' },
      { id: 'HYU_CANNISTER', key: 'service3Cannister' },
      { id: 'HYU_ENGINE_AIR_FILTER_REPLACE', key: 'service3EngineFilter' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateService1EV(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_BATTERY_STATE_OF_CHARGE', key: 'service1SOC' },
      { id: 'HYU_BATTERY_STATE_OF_HEALTH', key: 'service1SOH' },
      { id: 'HYU_LATCHES_HINGES_LOCKS', key: 'service1Latches' },
      { id: 'HYU_DRIVESHAFTS_AND_BOOTS', key: 'service1Driveshaft' },
      { id: 'HYU_STEERING_LINKAGES', key: 'service1Steering' },
      { id: 'HYU_CHECK_COOLANT_LEVEL', key: 'service1Coolant' },
      { id: 'HYU_AIR_CONDITION_REFRIGERANT', key: 'service1Refrigerant' },
      { id: 'HYU_ELECTRICAL_SYSTEMS', key: 'service1Electrical' },
      { id: 'HYU_BOLTS_NUTS_CHASSIS_BODY', key: 'service1BoltsNuts' },
      { id: 'HYU_FRONT_SUSPENSION_BALL_JOINTS', key: 'service1FrontSuspension' },
      { id: 'HYU_BRAKE_PEDAL_PLAY', key: 'service1BrakePedal' },
      { id: 'HYU_AIR_CONDITION_COMPRESSOR', key: 'service1Compressor' },
      { id: 'HYU_BRAKE_FLUID_CHECK', key: 'service1BrakeFluid' },
      { id: 'HYU_COOLING_SYSTEM_HOSES_CONNECTIONS', key: 'service1CoolingSystem' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateService2EV(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_AC_HEATING_FUNCTIONS', key: 'service2ACHeating' },
      { id: 'HYU_CABIN_AIR_FILTER', key: 'service2CabinAirFilter' },
      { id: 'HYU_BRAKE_SYSTEM_LINES_HOSES_PARKING', key: 'service2BrakeSystem' },
      { id: 'HYU_SHOCKS_STRUTS_SUSPENSION', key: 'service2Shocks' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateService3EV(form: PDFForm, inspection: any) {
    const serviceCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Check Following Systems & Components'
    );

    if (!serviceCategory) return;

    const tasksToCheck = [
      { id: 'HYU_VISUAL_FLUID_LEAKS', key: 'service3Leaks' },
      { id: 'HYU_BRAKE_SERVICE', key: 'service3Brakes' },
    ];

    tasksToCheck.forEach(({ id, key }) => {
      const task = serviceCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkGYRTaskWithPrefix(form, task, key);
    });
  }

  populateAdditionalRecommendations(form: PDFForm, inspection: any, inspectionType: 'ICE' | 'EV') {
    const miscellaneousCategory = inspection.categories.find(
      (category: any) => category.categoryName === 'Miscellaneous'
    );

    if (!miscellaneousCategory) return;

    const baseTasks = [
      { id: 'HYU_FLOOR_LINERS_INSTALLED', key: 'recommendationsFloorLiners' },
      { id: 'HYU_SEASONAL_TIRES_CHANGED', key: 'recommendationsSeasonalTireChange' },
      { id: 'HYU_SEALANT_EXPIRATION_CHECK', key: 'recommendationsSealant' },
    ];

    const iceTasks = [{ id: 'HYU_FUEL_INJECTOR_CLEANER', key: 'recommendationsFuelCleaner' }];

    const evTasks = [
      { id: 'HYU_V2L_INSTALLED', key: 'recommendationsV2L' },
      { id: 'HYU_LOW_CONDUCTIVITY_COOLANT_REPLACEMENT', key: 'recommendationsCoolant' },
    ];

    // Merge base tasks with ICE or EV-specific tasks
    const tasksToCheck = baseTasks.concat(inspectionType === 'ICE' ? iceTasks : evTasks);

    tasksToCheck.forEach(({ id, key }) => {
      const task = miscellaneousCategory.tasks.find((task: any) => task.inspectionId.includes(id));
      this.checkYNTaskWithPrefix(form, task, key);
    });

    const recommendationsCommentsField = form.getTextField('recommendationsComments');
    // recommendationsCommentsField.setText('Some default recommendation text.'); // PLACEHOLDER
  }

  // all mocked for now
  populateNextVisit(form: PDFForm, inspection: any) {
    const nextServiceDateField = form.getTextField('nextServiceDate');
    //nextServiceDateField.setText('March 1st, 2025'); //PLACEHOLDER

    const nextServiceTimeField = form.getTextField('nextServiceTime');
    //nextServiceTimeField.setText('11:00 am'); //PLACEHOLDER

    const nextServiceMileageField = form.getTextField('nextServiceMileage');
    //nextServiceMileageField.setText('35000'); //PLACEHOLDER

    const recommendedService1Checkbox = form.getCheckBox('recommendedService1');
    //recommendedService1Checkbox.check(); //PLACEHOLDER

    const recommendedService2Checkbox = form.getCheckBox('recommendedService2');
    //recommendedService2Checkbox.check(); //PLACEHOLDER

    const recommendedService3Checkbox = form.getCheckBox('recommendedService3');
    //recommendedService3Checkbox.check(); //PLACEHOLDER

    const nextServicePriceField = form.getTextField('nextServicePrice');
    //nextServicePriceField.setText('250'); //PLACEHOLDER
  }

  populateCompleted(form: PDFForm, appointment: any) {
    const advisorNameField = form.getTextField('advisorName');
    advisorNameField.setText(
      `${appointment.embedded.serviceAdvisorAssociate.firstName} ${appointment.embedded.serviceAdvisorAssociate.lastName}`
    );

    const technicianNameField = form.getTextField('technicianName');

    if (appointment.technicianId) {
      technicianNameField.setText(`${appointment.technicianGroupName || ''}`);
    }
  }

  checkGYRTaskWithPrefix(form: PDFForm, task: any, prefix: string) {
    const greenCheckbox = form.getCheckBox(`${prefix}_G`);
    const yellowCheckbox = form.getCheckBox(`${prefix}_Y`);
    const redCheckbox = form.getCheckBox(`${prefix}_R`);

    if (task.greenBox) {
      greenCheckbox.check();
    } else if (task.yellowBox) {
      yellowCheckbox.check();
    } else if (task.redBox) {
      redCheckbox.check();
    }
  }

  checkGRTaskWithPrefix(form: PDFForm, task: any, prefix: string) {
    const greenCheckbox = form.getCheckBox(`${prefix}_G`);
    const redCheckbox = form.getCheckBox(`${prefix}_R`);

    if (task.greenBox) {
      greenCheckbox.check();
    } else if (task.redBox) {
      redCheckbox.check();
    }
  }

  checkGTask(form: PDFForm, task: any, pdfField: string) {
    const greenCheckbox = form.getCheckBox(pdfField);

    if (task.greenBox) {
      greenCheckbox.check();
    }
  }

  checkRTask(form: PDFForm, task: any, pdfField: string) {
    const redCheckbox = form.getCheckBox(pdfField);

    if (task.redBox) {
      redCheckbox.check();
    }
  }

  checkYNTaskWithPrefix(form: PDFForm, task: any, prefix: string) {
    const greenCheckbox = form.getCheckBox(`${prefix}_Y`);
    const redCheckbox = form.getCheckBox(`${prefix}_N`);

    if (task.greenBox) {
      greenCheckbox.check();
    } else if (task.redBox) {
      redCheckbox.check();
    }
  }

  checkBrakeTaskWithPrefix(form: PDFForm, task: any, prefix: string, brakeMeasurementIds: number[]) {
    const greenCheckbox = form.getCheckBox(`${prefix}_G`);
    const yellowCheckbox = form.getCheckBox(`${prefix}_Y`);
    const redCheckbox = form.getCheckBox(`${prefix}_R`);

    switch (task.brakeMeasurementId) {
      case brakeMeasurementIds[0]:
        greenCheckbox.check();
        break;
      case brakeMeasurementIds[1]:
        yellowCheckbox.check();
        break;
      case brakeMeasurementIds[2]:
        redCheckbox.check();
        break;
      default:
        break;
    }
  }

  checkOkFilledTask(form: PDFForm, task: any, prefix: string) {
    const okCheckbox = form.getCheckBox(`${prefix}_ok`);
    const filledCheckbox = form.getCheckBox(`${prefix}_filled`);

    if (task.greenBox) {
      okCheckbox.check();
    }

    if (task.fluidFilled) {
      filledCheckbox.check();
    }
  }
}
