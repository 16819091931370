export const environment = {
  apiBase: 'https://staging-api.xsellerator.com',
  quorumApiBase: '.quorum-api.com',
  communicatorUrl: 'https://staging-server.qcommunicator.com',
  hqRedirectUrl: `https://staging-hq.xsellerator.com`,
  s3AttachmentsBucket: 'staging-communicator-attachments',
  s3CreatePresignedUrl: 'https://g2bldutsei.execute-api.us-east-1.amazonaws.com/staging/s3-create-presigned-url',
  legacyApiBase: 'https://stagingapi.azurewebsites.net',
  legacyAuthBase: 'https://stagingauthentication.azurewebsites.net',
  mediaPresignUrl: 'https://qnfhpo4cc1.execute-api.us-east-1.amazonaws.com/staging/request-url',
  multiPartMediaUploadUrl: 'https://hj1rmionpg.execute-api.us-east-1.amazonaws.com/staging',
  metadataScraperUrl: 'https://ie8enk77t5.execute-api.us-east-1.amazonaws.com/',
  htmlConversionUrl: 'https://nchnofi02h.execute-api.us-east-1.amazonaws.com',
  qTemplatesPlatformUrl: 'https://staging-qtemplates.quorum-api.com',
  googleCloud: 'AIzaSyCLlPkj8_SZC5PEXgNPYAOgaXcKChY_LAk',
  production: true,
  environment: 'staging',
  version: '1.1.1',
  noticeable: {
    iconWidgetIdPowerLane: 'jXjT7rbeqS3551MytW28',
    iconWidgetIdSalesPlanner: 'XqZ7z6Zmaepz2LKlBCNa',
    iconWidgetIdCommunicator: 'RGpGZyqSM4E9w8PzJYCU',
    sharedProjectId: 'ZUegJ9JmBeCIdB1tgQOD',
  },
  jwtTokenGenerationUrl: 'https://pd05owwm1k.execute-api.us-east-1.amazonaws.com/staging/generateToken',
  createSSMParamUrl: 'https://r5l9zqh6na.execute-api.us-east-1.amazonaws.com/staging/createSSMParam',
};
